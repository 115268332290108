// 0. General
@import url('constants.scss');
@import 'themes/theme-light';
@import 'themes/theme-dark';


.header {
    background-color: #2f2f2f;
    color: whitesmoke;
    padding: 25px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}
.quote {
    font-weight: normal;
}
.text-green-bold {
    font-weight: bold;
    color: #95c515!important;
}
.main-list {

    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: larger;
    & > li {
        list-style: none;padding: 0;margin: 0;text-align: center;margin-bottom: 40px;font-weight: bold;font-size: larger}
}
.separator {
    list-style: none;padding: 10px;margin: 0;border: none;border-bottom: 1px solid #95c51533;
}
.text-simple {
    font-weight: normal;
    color:whitesmoke;
}
.best-keys {

    border: 2px solid #0f693850;
    width: auto;
    background: white;
    display: inline-block;
    padding:1% 10%;
    margin-top: 2%;
    font-size: 1.0rem;
    text-align: left;
    code {
        font-weight: normal;
        color: #a31515;
    }
}
.code-container {
    background-color: whitesmoke;
    width: 80%;
    margin:10px auto 0;
    padding: 1%;
    text-align: left;
    font-weight: normal;
}

.link-list {


    li {
        font-weight: normal;
        font-size: .8em;
        list-style: none;display: inline-block;
    }
    a {

        text-decoration: none!important;
        color: #7bb4c2!important;
        &:hover {
            font-weight: bold;
        }
    }
}

.footer {

    background-color: #2f2f2f;padding: 0px 0 0px 0;font-size: 14px;text-align: center;
     display: block;position:relative;bottom: 0%;width: 100%;font-weight: bold;
    .footer-quote {
        color: whitesmoke;
        width: 80%;
        margin:20px auto 0;
        padding: 2%;
        font-weight: normal;
    }
}



// 1. General
html {
    overflow: hidden!important;
    overflow: auto!important;
}
body {

    font-family: -apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;
    font-family: 'Courier New', Courier, monospace;
    font-family: monospace, Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-size: .9em;
    background-color: #2f2f2f!important;
    color: #95c515!important;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
:not(pre):not(code) {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}


// 0. HTML
// TODO: Background de ejemplo. Quitar!
#et-main-area {
    // border: 2px solid red;
    // position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    // margin-top: 100px!important;
}
#browser_root {
    background: none!important;
    margin: 0 auto!important;
    position: relative;
    text-align: center;
    color: #36363677;
    // margin-top: 170px!important;
    
    // Hack para arreglar la integración con WP!
    &>div {
        min-height: unset!important;
        max-height: unset!important;
        overflow: hidden!important;
        &>div {
            min-height: unset!important;
            max-height: unset!important;
            overflow: hidden!important;
        }
    }
    .is-standalone {
        &.is-theme-light {
            background-color: transparent!important;
        }

        #cazatucasa_search_engine_v1 {
            background: none!important;
            background-size: unset;
            margin: 0!important;
            padding: 0!important;
        }
        .show-on-hover {
            // We don't show the tabs in standalone mode
            height: 10px!important;
            visibility: hidden;
        }
    }
    .is-localhost {
        &.is-theme-light {
            background-color: whitesmoke!important;
        }
    }

    .tabs-tile {
        // border: 2px solid red;
        align-items: center;
    }
}

// 3. NAV MENU (Generic. Move to component!)
.navbar {


    // Fixed navbar!!
    // background-color: transparent!important;
    // border: 0px solid transparent!important;
    position: absolute!important;

    position: fixed!important;
    left: 0;
    top: 0;
    z-index: 2;
    background-color:#0089a1!important;
    justify-content: space-around;
    width: 100%;
    cursor: default;
    user-select: none;

    // Original
    border-bottom: 1px solid #ccc;

    .navbar-item, .navbar-link {

        // color: #0087d2c4;
        color: whitesmoke;
        background: transparent;
        border: none;

        &:hover {
            /// color: #333;
            background: transparent;
            border: none;
        }
        &:active {
            border: none;
            ouline: none;
        }
        &:focus {
            border: none;
            outline: none;
        }
    }
    .navbar-menu {

        flex-grow: .5;
        justify-content: center;
        .navbar-item {

            flex-grow: 1;
            justify-content: center;
            .control {

                width: 50%;
            }
        }
    }

    .react-version {
        margin: 0 40px;
    }
}
.fallback-navbar {
    text-align: center;
    background: transparent;
    border: none;
    height: 20px;
}

// FOOTER
//
// Customize the git icon and the font
.app-footer {
    background-color: #333!important;
    color: whitesmoke!important;
    font-size: 12px;
    a {
        color: #777;
    }
    .material-icons {
        margin-right: 10px;
    }
}
