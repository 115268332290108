@import "./constants";



// Search Results
.result-info {
    // border: 2px solid red!important;
    .level-item {
        justify-content: center;
        padding: 5px;
        .result-info-icon {
            position: relative;
            top:1px;
            margin-right: 8px;
            color : #2B3A4Ccc;
            svg {
                font-size: 1.8rem!important;
            }
        }
        .result-info-text {
            font-size: 1.1rem!important;
        }

        &.map-level {
            color: $linkColor;
            &:hover {
                cursor: pointer;
                // font-weight: 600;
                background: #0275d817;
                // font-weight: bold;
            }
        }
    }
    &.result-location {
        position: relative;
        top: -8px;
        
        &:hover {
            color: $linkColor;
        }

        .level-item {
            justify-content: flex-start;
            font-size: 1.2rem;
            .result-info-icon {
                top: 2px;
            }
            .result-info-text {
                font-weight: 400;
            }
        }
    }

}
.clean-filter-message {
    font-size: 1.5rem;
    font-family: $font_family_text;
    font-weight: 300;
    color: $font_color;
    padding: 0px 0px;
    
    margin: 25px 5px 5px 0px;
    background: #6a6a6a22;
    height: 200px;

    P {
        // border: 2px solid red;
     //   width: 100%;
    }
    button {
        // border: 2px solid red;
        // width: 100%;
        color : $font_color!important;
    }
}
.box.no-results {
    height: fit-content;
    .search-results-tile {
        padding: 0px!important;
        font-weight: 300;
    }
    .title {
        font-size: 1.5rem;
        font-weight: 600;
        color: $font_color;
        text-transform: uppercase;
    }
    hr {
        // outline: 1px solid #aaa;
        margin: 20px 0px 20px 0px;
        background-color: #2B3A4C66!important;
        height: 1px;
        // padding: 4px;
    }
    .MuiSvgIcon-root {
        font-size: 2.5rem!important;
        color: $font_color;
        margin-right: 0px;
        margin-left: 10px;
    }


    button {
        .MuiSvgIcon-root {
            font-size: 1.5rem!important;
            color: $font_color!important;
            margin-right: 7px;
            margin-left: 0px;
        }    
    }
}
// Search Results
.search-results-container {
    
    // border: 2px solid red;
    font-size: 0.8rem;    
    font-family: $font_family_text;
    font-weight: 300;
    width: 100%;
    .box {

        padding: 10px;

        .tile-vertical-aligned-top {
            align-self: flex-start;
        }

        
        &.result {
                    // border: 2px solid red!important;
                    padding: 12px!important;
                    // margin-top: 25px;

            .tile {

                &.is-ancestor {
                    padding: 0px!important;

                    .is-vertical {
                        
                       // box-shadow: 0px 1px 30px 0px rgba(148,148,148,0.48)!important;
                    }
                }

                .is-parent {
                    padding: 0!important;
                    margin: 0!important;
                    max-height: 340px;


                    &.result-summary {
                        // border: 2px solid red!important;
                        padding: 15px 20px!important;
                        .content {
                            // border: 2px solid red!important;
                            padding: 0px!important;
                            margin-bottom: 0!important;

                            // Si pongo éstas 2 van a la izquierda
                            // display: flex;
                            // flex-wrap: wrap;

                        }
                    }


                    .result-image {
                        height: 100%;
                        padding: 0!important;
                        margin: 0!important;
                        img {
                            width: 100%;
                            // height: 100%;
                            object-fit: cover;
                            object-position: center;
                            cursor: pointer;
                        }
                    }  

                    .title {
                        font-size: 1.7rem; // Setearla es una joda
                        font-weight: 600;
                        color: #7a2e59;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0  0 20px 0;
                        text-align: left;
                        font-family: $font_family_text!important;
                        
        
                        // margin-bottom: 30px!important;
                        // text-transform: uppercase;
                    }
                    .result-info-description {
                        //border: 2px solid red;
                        padding: 0px 15px 0px 0px!important;
                        margin: 10px auto 12px auto!important;
                        font-size: 1.2rem;
                        text-align: justify;
                        font-weight: 300;
                        color: #777;
                        // border: 2px solid red;;
                        cursor: pointer;
                        // pointer-events: none!important;

                        font-size: 1.2rem;
                        max-height: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        position: relative;
                        display: block;

                        .ellipsis-gradient {
                            position: absolute;
                            display: block;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                            background: linear-gradient(to right bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        }
                     
                    }
                    .result-info-price {
                        font-size: 1.8rem;
                        font-weight: 300;
                        color: #333;
                        
                        // text-transform: uppercase;
                    }
                    .result-info-features {
                        font-size: 1.1rem;
                        font-weight: 300;
                        color: #333;
                        // text-transform: uppercase;
                    }
                    .result-actions {

                        height: 70px;
                        // padding-bottom: 20px!important;
                        position: relative;
                        // top: -10px;
                        .content {
                            display: flex;
                            text-align: center;
                            button {
                                font-weight: 600!important;
                                font-size: 1.0rem;
                                flex-grow: 1;
                                &:hover {
                                    // text-decoration: underline!important;
                                }
                                .MuiSvgIcon-root {
                                    // font-size: 1.1rem!important;
                                    margin: 0px 0;
                                }
                                &.is-contact-button {
                                    &:hover {
                                        color: #0366b2;
                                        background-color: #0366b211;
    }
                                }
                                &.is-phone-button {
                                    &:hover {
                                
                                        color: #256c31;
                                        background-color: #256c3111;
                                    }
                                }
                                &.is-delete-button {
                                    &:hover {
                                        color: #7a2e59;
                                        background-color: #7a2e5911;
                                    }
                                }
                            }
                        }
                    }
                }
                
            }

            .is-card {
                // border: 2px solid red;
                flex-direction: column;
                
                .featured-image-container {
                    height: 200px;
                    width: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 10px 10px 0 0;
                        box-shadow:  0px 1px 30px 0px rgba(148,148,148,0.48);
                    }
                }
                .featured-title-container {
                    .title {
                        font-size: 1.2rem;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                }
                .featured-description-container {
                    // border: 2px solid red;
                    margin: 0;
                    padding: 0;
                    .result-info-description {
                        // border: 2px solid red;
                        margin: 0;
                        padding: 0;
                        font-size: 1rem;
                        font-weight: 400;
                        max-height: 95px;
                        height: 95px;
                        // max-height: unset;
                        padding: 10px 0px!important;
                        margin: 10px auto 12px auto !important;
                        white-space: break-spaces;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        position: relative;
                        display: block;
                        text-align: left;
                        .ellipsis-gradient {
                            display: none;
                        }
                        &::after {
                            content: "...";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 1.2rem;
                            background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 100%);
                        }
                    }
                }
                .features-list-container {
                    // border: 2px solid red;
                    overflow: hidden;
                    margin: 0!important;
                    padding: 0!important;
                    position: relative; 
                    top: 8px;
                    .result-info-icon {
                        margin-right: 5px;
                    }
                    .result-info-text {
                        font-size: .8rem!important;
                    }
                    
                }
                // .featured-description-container {
                //     border: 2px solid red;
                //     margin: 0;
                //     padding: 0;
                //     .featured-description {
                //         border: 2px solid red;
                //         margin: 0;
                //         padding: 0;
                //         font-size: .8rem;
                //         font-weight: 400;
                //     }
                // }
                .featured-action-buttons {
                    .button {
                        span {
                            display: none;
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .featured-title-container {
                // border: 2px solid red;
                padding: 0 15px;
                margin: 0;
                .title {
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }

            &.result-skeleton {
                // border: 2px solid red!important;
                     
                padding-bottom: 5px!important;
                .tile {
                    .is-parent {
                            &.result-summary {
                                // padding: 0px!important;
                        }
        
                    }
            }
        }
    }

    .features-list-container {

        nav {
            // border: 2px solid red;
            display: flex;
            flex-wrap: wrap;
        }
    }
}
}
.button-icon {
    font-size: 1.8rem!important;
    margin: 0 10px 0 0!important;
}
.link-icon {
    font-size: 1.5rem!important;
    position: relative;
    top: 5px;
    margin-left: 10px;
}
// Tabs
.tab-widget-container {
    justify-content: center;
    .tabs-container {
        // max-width: 1111px;  
    }  
    .show-on-hover {
        // opacity: 0.0;
        &:hover {
            // opacity: 1.0;
        }
    
        transition: all .4s ease-in-out;
    }
      
}
// Mini Slider
.photos-mini-slider-container {
    .MuiImageList-root {
        // border: 2px solid red;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        .MuiImageListItem-root {
            height: 100%;
            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}
.detail-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 40px!important;
    button {
        font-size: 1.0rem!important;
        border-radius: 0;
        &:hover {
            background: $linkColor!important;
            color: white;
        }
    }
}
.featured-action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 00px;
    margin-bottom: 00px!important;
    width: 100%;
    place-self: flex-end;
    
    button {
        background-color: transparent!important;
        font-weight: 600!important;
        font-size: 1.0rem;
        flex-grow: 1;
        &:hover {
            // text-decoration: underline!important;
        }
        .MuiSvgIcon-root {
            // font-size: 1.1rem!important;
            margin: 0px 0;
        }
        &.is-contact-button {
            &:hover {
                color: #0366b2;
                background-color: #0366b211!important;
}
        }
        &.is-phone-button {
            &:hover {
        
                color: #256c31;
                background-color: #256c3111!important;
            }
        }
        &.is-delete-button {
            &:hover {
                color: #7a2e59;
                background-color: #7a2e5911!important;
            }
        }
    }

}
// Featured Image
.featured-image-container {
    height: 100%;
    position: relative;
    display: flex;
    // place-items: flex-end;


    .result-image {
        padding: 0!important;
        margin: 0!important;
        width: 100%;
        
        height: 500px;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        }
    }
    
    .featured-image-level {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #00000055;
        min-height: 70px;
        transition : all .2s ease-in-out;
        // border: 2px solid blue;
        z-index: 3;
        .level-item {
            // border: 2px solid red;
            justify-content: flex-start;
            font-size: 1.2rem;
            .result-info-icon {
                top: 2px;
            }
            .result-info-text {
                font-weight: 400;
            }

            button {

                // background: #2B3A4Caa!important;
                color: #ffffffdd!important;
                font-size: 1.3rem;
                font-weight: 600;
                padding: 10px 10px;
                border-radius: 10px;
                border: 0px solid black;
                opacity: 1;
                // background: #ffffff00!important;
                font-weight: bold;
                span.how-many {
                    font-weight: 600;
                    font-size: 1.3rem;
                    margin-right: 10px;
                }
                span.text-button {
                    font-weight: 400;
                //    display: none;
                }
                &:hover {
                   opacity: 1;
                   background: #00000066!important;
                   span.text-button {
                       display: inline;
                   }
                }
                &:focus {
                    outline: none;
                    background: #00000066!important;
                }
                &:focus-visible {
                    outline: none;
                    background: #00000066!important;
                }
                transition : all .2s ease-in-out;
           
           
                &.video-button {
                    
                    margin-left: 1.1rem;
                }
        
                
                &.pictures-button {
                        
                        margin-right: 1.1rem;
                }
        
                // &.is-outlined:hover {
                //     background-color: #ffffff00!important;
                // }
           
            }
        }
    }

    button.left, button.right {
        // border: 2px solid red!important;
        position: absolute;
        right: 0;
        margin: 0 20px;
        width: 12%;
        height: 100%;
        cursor: default;
        // background-color: #ffffff99!important;
        // background-color: #ffffffff!important;
        border-radius: 0px;
        padding: 5px 8px;
        z-index: 2;
        top: 0%;
        transition: all .2s ease-in-out;
        .MuiSvgIcon-root {
            color: #ffffff55!important;
            font-size: 2.5rem!important;
            background-color: #00000055!important;
            border-radius: 5%;
            padding: 5px;
            transition: all .2s ease-in-out;
            cursor: pointer;
        }
        &:hover {
            .MuiSvgIcon-root {
                background-color: #00000099!important;
                color: #ffffffdd!important;
                font-size: 3rem;
            }
            
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:focus-visible {
            outline: none;
            box-shadow: none;
        }

        &.left {
            left: 0;
            right: auto;
        }
        &.right {
            left: auto;
            right: 0;
        }
    }

}
// Search results header
.search-results-summary-container,
.search-detail-summary-container {
    position: relative;
    &.is-map-full-screen {
        // border : 4px solid red;
        position: fixed;
        top: 120px;
        left: 0;
        z-index: 1000000000000;
        display: flex;
        width: 100%;
        .box {
            margin: 0 auto;
            width: auto;

            .one-filter {
                display: inline-flex;
            }
        }
    }
    .box {
        padding: 10px 20px!important;
        &.summary {
            display: flex;
            &>nav {
                width: 100%;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .level-item {
                    p {
                        font-weight: 400;
                        font-size: 1.1rem!important;
                        display: inline-block;
                        height: 100%;
                    }
                    justify-content: flex-start;
                    .result-info-icon {
                        top: 2px;
                    }
                    .result-info-text {
                        // font-size: 1.2rem!important;
                    }
                    .MuiInputBase-root {
                        .MuiSelect-select {
                            background: #ffffffaa;
                        }
                        .MuiSvgIcon-root {
                            background: transparent!important;
                            color: #2B3A4C!important;
                        }
                    }
                }
    

                .page-results-buttons {
                    // border: 2px solid red;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;                    
                }
                
                .page-results-text {
                    // border: 2px solid red;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 20px;
                    
                    span {
                        font-size: 1.1rem;
                        font-weight: 400;
                        padding-left: 10px;
                    }
                    .MuiInputBase-root {
                        .MuiSelect-select {
                            background: #00000033;
                        }
                        .MuiSvgIcon-root {
                            background: transparent!important;
                            color: white!important;
                        }
                    }
                }

                .mobile-page-results-page {
                    .page-results-text {
                        display: none;
                    }
                }
            }
        }
    }
}
ul[aria-labelledby="searchSort"],
ul[aria-labelledby="searchSortByDirection"],
ul[aria-labelledby="resultsPerPage"] {
        li {
            padding: 6px 16px!important;
            // border: 2px solid red;
    }
}
.scroll-pointer {
    width: 100%;
    height: 1px;
    background: #ffffff00;
    z-index: 100;
    cursor: pointer;
    transition : all .2s ease-in-out;
    
    // border: 2px solid red;
    color: transparent;

    &.extra-padding {
        top: -53px;
        display: block;
        position: relative;
    }
}
// Searching loader
.search-results-section {
    
    // border: 2px solid red;
    // min-height: 1200px!important;
    width: -webkit-fill-available;
    .searching {
        // position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #00000022;
        z-index: 100;
        display: flex;
        justify-content: center;
        margin-bottom: 1000px!important;
        align-items: center;
        .loader {
            width: 100px;
            height: 100px;
            border: 10px solid #f3f3f3;
            border-radius: 50%;
            border-top: 10px solid #3498db;
            width: 80px;
            height: 80px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
        }
        .searching-text {
            font-size: 1.5rem;
            font-weight: 400;
            color: #2B3A4C;
            margin-left: 30px;

        }
    }
}
.place-top.type-info {
    // border: 2PX SOLID RED;
    font-size: 1.2rem;
    font-weight: 500;
}
.is-blue-dark {
    color: #2B3A4C!important;
}
.search-button {
    margin-right: 10px;
    &:hover {
        opacity: 1;
        // background: #bcac71;
        span.text-button {
            display: inline;
        }
     }
     transition : all .2s ease-in-out;
 }
.map-search-button {
    // border: 2px solid red!important;
    padding: 22px 10px!important;
    &:hover {
       opacity: 1;
    //    background: #bcac71;
       color: #2B3A4C!important;
       span.text-button {
           display: inline;
       }
    }
    transition : all .2s ease-in-out;
}
.box.searching {
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    .content {
        // border: 2px solid red;
        width: 100%;
        display: flex;
        flex-direction: row;
        span {
           min-height: 150px;
           height: 20%;
           display: block;
        }
    }
}
// Ajuste para los tiles
.tile.is-ancestor:not(:last-child) {
    margin: 0;
}
// Action button tooltips (ver teléfono, contacts y eliminar
.action-button-tooltip {
    // border: 2px solid red!important;
    box-shadow: 0px 0px 10px 0px #00000066!important;
    margin-top: -20px!important;
    background-color: $linkColor!important;
    border-radius: 5px!important;
   
    a {
        color: white!important;
        text-align: center!important;
        font-size: 1.6rem!important;
        font-weight: 400!important;
        padding: 10px 20px!important;
           
        // Visited as white
        &[visited] {
            color: white!important;
        }
    }

    // Arrow of popup in blue
    &:after {
        border-top-color: $linkColor!important;
        box-shadow: 0px 0px 10px 0px #00000066!important;
    }

    &.is-phone-button {
        background-color: $red!important;
        &:after {
            border-top-color: $linkColor!important;
            box-shadow: 0px 0px 10px 0px #00000066!important;
        }
    }
}
.search-results-map {
    width: 100%;
    position: relative;
    .is-map-container {
        height: 31vw;
        min-height: 500px;
        width: 100%;
        // .leaflet-container {
        //     .leaflet-pane {
        //     }
        // }
        .leaflet-container {
            // border: 2px solid red;
            width: 100%;
            height: 100%;
            z-index: 1;

            .leaflet-popup {
                .leaflet-popup-content-wrapper {
                    background-color: transparent;
                    .leaflet-popup-content {
                        margin: 0;
                        padding: 0;
    
                        .result {
                            // border: 2px solid red;
                            margin-bottom: 0;
                            background-color: transparent;
                            .result-summary {
                                background-color: white;
                                border-radius: 10px;
                            }
                        }
                    }    
                }
            }
        }
    }
    
    &.is-map-full-screen {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background: white;

        .is-map-container {
            height: 100%;
            .leaflet-container {
                height: 100%;
                width: 100%;
            }
        }
    }
    .full-screen-link {
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1000;
        color: #000000aa;
        background-color: #00000022;
        font-size: 2rem;
        cursor: pointer;
        transition : all .2s ease-in-out;
        // border: 2px solid rebeccapurple;
        margin: 0;
        padding: 0;
        height: 40px;
        width: 40px;
        opacity: 0.6;
        .MuiSvgIcon-root {
            margin: 0;
            padding: 0;
            height: 30px;
            width: 30px;
        }

        &:hover {
            opacity: 0.8;
            background-color: #2c8ac977;
        }
    }    
}
.is-tooltip-for-map {
    // color: whitesmoke!important;
    // background-color: $linkColor!important;
    background-color: #ffffffdd!important;
    font-weight: bold!important;
}

////////// IS LOADING SECTION //////////
.results-loading-skeleton-stack {
    // border: 2px solid red;
    width: 100%;
    display: flex;
    padding: 20px!important;
    
}
// Go back search
.go-back-search {
    // border: 2px solid red;
    &:hover {
        cursor: pointer;
        background: rgba(85, 163, 205, 0.72);
        span {
            color: white;
            font-weight: bold;
        }
    }
}
.go-to-top {
    // border: 2px solid red;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    color: #000000aa;
    background-color: #00000022;
    font-size: 2rem;
    cursor: pointer;
    transition : all .2s ease-in-out;
    // border: 2px solid rebeccapurple;
    margin: 0;
    padding: 0;
    height: 40px;
    width: 40px;
    opacity: 0.6;
    .MuiSvgIcon-root {
        margin: 0;
        padding: 0;
        height: 30px;
        width: 30px;
    }

    &:hover {
        opacity: 0.8;
        background-color: #2c8ac977;
    }
}

////////// DETAIL PAGE //////////
/// 
/// 
/// 
.search-detail-container {
    .detail-box {
        
        font-family: $font_family_text!important;

        .tile {
            // border: 2px solid red;
            &>.box {
                // border: 2px solid blue!important;
                padding: 15px 20px;
                &.floating-box {
                    padding: 15px 30px 35px !important;
                }
            }
        }
        .detail-header {
            // border: 2px solid red;
            margin-top: 10px;
            padding-bottom: 30px;
            .MuiSvgIcon-root {
                position: relative;
                top: 5px;
                left: 3px;
            }
        }   
        .title {
            font-size: 2.0rem;
            font-weight: 600;
            color: #7a2e59!important;
            line-height: 2.5rem;
            font-family: $font_family_text!important;
            // text-transform: uppercase;
        }
        .detail-image {
            height: 100%;
            padding: 0!important;
            margin: 0!important;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .detail-info {
            padding: 0 0px!important;
            margin: 0px 0px 0px 0px!important;
            font-size: 1.9rem;
            text-align: justify;
            padding-right: 10px;
            font-weight: 300;
            color: #777;
            box-shadow: none!important;
            // border: 2px solid red;;
            margin: 1.5rem auto 0px auto!important;
            &>div {
                margin-bottom: 20px;
            }
        }
        .detail-info-price {
            font-size: 1.8rem;
            font-weight: 300;
            color: #333;
            
            // text-transform: uppercase;
        }
        .detail-info-name {
            display: inline-block;
            margin-right: 30px;
            // text-transform: uppercase;
        }
        .detail-info-features {
            font-size: 1.5rem;
            font-weight: 300;
            color: #333;
            // text-transform: uppercase;
        }
        .detail-info-location{
            font-size: 1.5rem;
            font-weight: 300;
            color: #333;
            display: inline-block;
            // text-transform: uppercase;
            margin-right: 20px;
        }
        .detail-info-map{
            font-size: 1.2rem;
            font-weight: 400;
            color: $linkColor;
            display: inline-block;
            // text-transform: uppercase;
    
            svg {
                // border: 2px solid red;
                margin-right: 4px;
                position: relative;
                top: 5px;
                font-size: 1.9rem!important;
            }
        }
        .detail-actions {
            height: 70px;
            // padding-bottom: 20px!important;
            position: relative;
            // top: -10px;
            .content {
                display: flex;
                text-align: center;
                button {
                    font-weight: 600!important;
                    font-size: 2.0rem;
                    flex-grow: 1;
                    &:hover {
                        // text-decoration: underline!important;
                    }
                    .MuiSvgIcon-root {
                        // font-size: 1.1rem!important;
                        margin: 0px 0;
                    }
                }
            }
        }
        .detail-info-description, .detail-info-photos, .detail-info-video, .detail-info-description, .detail-info-tour-virtual {
            // border: 2px solid red;
            padding: 0px 0px 0px 0px!important;
            font-size: 1.3rem;
            text-align: justify;
            padding-right: 10px;
            font-weight: 400;
            color: #777;
            // border: 2px solid red;;
            margin: 1.5rem auto 1.5rem auto!important;
            
            h1 {
                font-size: 2.0rem;
                font-weight: 600;
                color: #7a2e59;
                margin-top: 50px;
                margin-bottom: 20px;
                font-family: $font_family_text!important;


            }
        }
        .detail-info-action-buttons {
            display: flex;
            justify-content: space-evenly;
            margin-top: 40px;
            margin-bottom: 50px!important;
            button {
                font-size: 1.5rem;
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 0;
                &:hover {
                    background: $linkColor;
                    color: white;
                }
            }
        }      
        .real-state-agent-profile-container {
            // border: 2px solid red;
            padding-top: 30px;
            &>div {
                padding: 0 30px;
                font-size: 1.1rem;
                
                &.real-state-agent-name {
                    font-size: 1.3rem!important;
                    font-weight: 600!important;
                    color: #2B3A4C;
                    margin-bottom: 10px;
                }
        
                a {
                    &:hover {
                        // font-weight: 600;
                        color: $linkColor!important;
                        text-decoration: underline;
                    }
                }
            }
        }   
        .photo-masonry {
            
            li {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                // El overlay de la imagen
                .photo-masonry__overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0.25);
                    opacity: 0;
                    transition: all 0.3s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    .MuiSvgIcon-root {
                        font-size: 3.5rem!important;
                        color: white;                        
                    }
                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
        
                }
            }

        }
        .featured-basic-info-container {
            // border: 2px solid red;
            nav {
                display: flex;
                flex-wrap: wrap;

                .level-item {
                    transition: all 0.3s ease;
                    place-content: start;
                    // width: 50%;
                    font-size: 1.3rem;
                    .result-info-icon {
                        margin-right: 10px;
                        font-size: 1.5rem;
                        position: relative;
                        top: 5px;
                    }
                    .result-info-label {
                        font-weight: 600;
                        color: #2B3A4C;
                        margin-right: 10px;
                    }

                    &:hover {
                    }
                }
            }
        }

        // Para los anchors
        .anchor.pointer {
            // border: 2px solid red;
            position: relative;
            top: -65px;
            width: 100px;
        }
    }    
}
.language-selector {
    border: none!important;
    color: white!important;
    font-size: .8rem!important;
    opacity: .7;
    .MuiSvgIcon-root {
        color: white!important;
    }
    fieldset {
        border: none!important;
    }
    &:hover {
        opacity: 1;
    }

}
.language-selector-options-container {
    border-radius: 3px!important;
    position: relative!important;
    margin-left: 0px!important;
    width: 120px!important;
        
    li {
        padding: 5px 10px!important;
        margin: 0px!important;
        font-size: .9rem;
        color: #555;
        text-indent: 40px;
        &[data-value="en"] {
            background: url('../../public/img/flags/en.png') no-repeat 10px 50%!important;
            background-size: 24px!important;
        }
        &[data-value="es"] {
            background: url('../../public/img/flags/es.png') no-repeat 10px 50%!important;
            background-size: 24px!important;
            
        }
        &[data-value="fr"] {
            background: url('../../public/img/flags/fr.png') no-repeat 10px 50%!important;
            background-size: 24px!important;
            
        }
        &[data-value="de"] {
            background: url('../../public/img/flags/de.png') no-repeat 10px 50%!important;
            background-size: 24px!important;
            
        }

        &:hover {
            background-color: #eee!important;
        }
    }

}
.video-player-container {
    position: relative;
    height: 0;
    &.has-video {
        padding-bottom: 56.25%; /* 16:9 */
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.tour-virtual-player-container {
    position: relative;
     height: 0;
  // border: 2px solid red;

  &.has-tour-virtual {
        // padding-bottom: 56.25%; /* 16:9 */
     //    border: 2px solid red;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // border: 2px solid red;
        html {
            border: 2px solid red;
            overflow: hidden;
        }
    }

}

.featured-image-slider {
    border: 2px solid red;
    width: 100px;
    .slick-slider {
        border: 2px solid red;
        .slick-list {
            margin: 0 -10px;
        }
        .slick-slide {
            padding: 0 10px;
        }
    }
}
// Undo redo arrows
.undo-redo-arrows {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // width: 50px;
    // height: 100%;
    // border: 2px solid red;
    .undo-arrow, .redo-arrow {
        width: 30px;
        height: 30px;
        
        // background: #fff;
        // border: 2px solid red;
        cursor: pointer;
        padding: 5px 0px;
        margin: 0px 5px;
        &:hover {
            // background: #eee;
            color: white;
        }
        .MuiSvgIcon-root {
            font-size: 1.5rem;
        }
        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }
        &:focus-visible {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }
}
// Skeleton loader
.skeleton-element {
    // border: 2px solid red!important;
    padding: 0px;
    min-height: 220px!important;
    .skeleton-results-img {
        margin: 0;
        width: 100%;
        height: 100%;
        // background: #eee;
        // border-radius: 5px;
    }
}
.toggler-is-searching {
    .toggler-is-searching-icon {
        color: white;
        position: relative;
        top: 2px;
        left: 4px;
    }
}
// Mapa en detalle
.detail-box .search-results-map {
    height: 100%;
    width: 100%;
    
    &>.box {
        // border: 2px solid red;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
    }
}
