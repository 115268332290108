@import "./constants";


/**

Bulma has 4 breakpoints which defines 5 screen sizes:

mobile: up to 768px --> pink
tablet: from 769px to 1023px --> green
desktop: from 1024px to 1215px --> red
widescreen: from 1216px to 1407px --> blue
fullhd: from 1408px --> gold



Elements impacted by the media queries:
    
- .search-detail-container
- .skeleton-results-img
    
    - .features-list-container
    
    
    - .search-results-summary-container
    - .search-detail-summary-container
    - .search-results-container
    

*/
/* Media queries for mobile */
@media screen and (max-width: 768px) {

    .action-button-tooltip a {
        font-size: 1.2rem!important;
        padding: 10px 10px!important;
    }
    // Container in PROD (inside Wordpress)
    .et_pb_row_0 {
        // border: 2px solid red;
        width: 100%!important;
    }
    // Hide completely tabs on mobile
    .tabs-container {

        padding: 1rem 0rem;

        // GENERAL: TABS
        .show-on-hover {
            display: none;
        }
        // GENERAL: PARA TODAS LAS CAJAS DE SUMARIO   
        .box.summary {
            nav {
                // border: 2px solid red;
                width: 100%;
                display: flex;
                justify-content: space-around;
                padding: 0;
                .page-results-text {
                    display: none;
                }
            }

            .page-results-buttons {
                flex-direction: column-reverse!important;
                .mobile-page-results-page>.page-results-text {
                    display: block!important;
                    padding: 15px 0px 0px 0px!important;
                }
            }
        }

        // BÚSQUEDA Y RESULTADOS
        .search-box-container {
            // border: 2px solid red;

            // Botnoes de búsqueda
            .search-button {
                    // border: 2px solid red;
                    width: 100%;
                    margin: 0 0% 20px 0%;
            }
        
            .map-search-button {
                // border: 2px solid red;
                margin: 0 0% 20px 0%;
                // float: right;
                width: 100%;
            }

            // Fixes to the summary
            .summary-level {
                // border: 2px solid red;
                display: flex;
                flex-flow: column;

                .subtitle {
                    // border: 2px solid red;
                    margin: 0!important;
                    // min-width: 110px;
                }
            }

            // Fixes to the results
            .results {
                // border: 2px solid red;
                // margin: 0!important;
                
                .result {
                    // margin: 0!important;
                    .featured-title-container {
                        padding: 0!important;
                        .title {
                            // border: 2px solid red;
                            margin-top: 25px;
                            margin-bottom: 40px;
                            white-space: normal;
                            text-align: center;
                            line-height: 2.5rem;
                        }    
                    }

                    // Tarjeta de resultados
                    .tile {

                        &.is-ancestor {
                            padding: 0px!important;

                            .is-vertical {
                                
                            // box-shadow: 0px 1px 30px 0px rgba(148,148,148,0.48)!important;
                            }
                        }

                        .is-parent {
                            max-height: unset!important;
                        }
                    }

                    // Description más alta
                    .result-info-description {
                        // border: 2px solid red;
                        max-height: 150px!important;
                    }

                    .result-actions {
                        height: auto!important;
                        .featured-action-buttons {
                            // border: 2px solid red;
                            display: flex;
                            margin-top: 20px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            button {
                                width: 100%;
                                margin-bottom: 10px;
                                font-size: .9rem!important;
                                border: 1px solid #ccc;
                                // padding: 10px 0!important;
                            }                            
                        }
                    }
                }
            }

        }
        // DETALLE
        .search-detail-container {
            // border: 2px solid pink;
            
            .search-detail-summary-container {
                // Barra de navegación
            }

            // Caja de detalle
            .detail-box {
                padding: 0!important;
                display: flex;
                flex-direction: column;
            }
            

            // Estiramos los detail buttons
            .detail-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                button {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
    
            // En el listado de detalles, ponemos sólo uno por línea        
            nav .level-item {
                width: 100%;
            }
    
            // Bajamos la fuente del título
            .featured-title-container {
                .title {
                    font-size: 1.8rem;
                }
            }
    
            // Le damos padding a la sección de detail-info-description
            .result-info-description {
                padding: 0px 10px!important;
                // border: 2px solid red;
                text-align: left;
    
            } 
            

        }
        // BÚSQUEDA IMAGEN DE ESQUELETO
        .skeleton-results-img {
            border: 0px solid pink;
            margin: 5% 6% 1%;
            height: -webkit-fill-available !important;
            width: 88%;
            height: 9rem!important;
        }

        // MAPA: Sumario cuando está en modo móvil y full screen
        .search-detail-summary-container {
            &.is-map-full-screen {
                // border: 2px solid red;
                top: unset;
                bottom: 20px;

                .box {
                    background: #fff !important;
                    margin: 0px 2rem !important;
                    width: 100%;
                    zoom: 0.8;
                }
            }
        }
    }
}
// /* Media queries for tablet */
@media screen and (min-width: 769px) {

        // GENERAL: PARA TODAS LAS CAJAS DE SUMARIO   
        .box.summary {
            nav {
                // border: 2px solid red;
                width: 100%;
                display: flex;
                justify-content: space-around;
                padding: 0;
                .page-results-text {
                    display: none;
                }

                .level-right {
                    margin-top: 40px;

                    .subtitle {
                        margin: 0!important;
                    }
                }
            }
        }

        // MAPA: Sumario cuando está en modo móvil y full screen
        .search-detail-summary-container {
            &.is-map-full-screen {
                // border: 2px solid red;
                top: unset;
                bottom: 40px;

                .box {
                    background: #fff !important;
                    margin: 0px 2rem !important;
                    width: 100%;
                    zoom: 0.8;
                }
            }
        }
    .search-detail-container {
        border: 0px solid green;
        // Estiramos los detail buttons
        .detail-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        // En el listado de detalles, ponemos sólo uno por línea        
        nav .level-item {
            width: 100%;
        }

        // Bajamos la fuente del título
        .featured-title-container {
            .title {
                font-size: 1.6rem;
            }
        }

        .detail-header {
            font-size: 1.6rem!important;
            .MuiSvgIcon-root {
                margin-bottom: 10px;
            }
        }

        // Le damos padding a la sección de detail-info-description
        .result-info-description {
            padding: 0px 10px!important;
            // border: 2px solid red;
           font-size: 1.3rem; 
        }

        .floating-box {
            padding: 15px 30px 35px !important;

            .action-header {
                // border: 2px solid red!important;
                font-size: 1.1rem;
                font-weight: normal!important;
                margin-top: 20px;
                margin-bottom: 10px;
                font-family: $font_family_text!important;
            }
        }

        .features-list-container {
            nav {
                flex-flow: wrap;
            }
        }
        .search-detail-summary-container {
            nav {
                // border: 2px solid red!important;
                
                justify-content: space-around;
            }
        }
    }
    .skeleton-results-img {
        border: 0px solid green;
        margin-bottom: 13px!important;
        margin-left: 25px;
    }
    .featured-image-container {
        .text-button {
            font-weight: 400;
            display: none!important;
        }            
    }
    // En la pantalla de detalle lo mostramos siempre!
    .detail-box {
        // border: 2px solid red!important;
        .featured-image-container {
            .text-button {
                font-weight: 400;
                display: block!important;
            }            
        }
    }
    .control-buttons-tile {
        // border: 2px solid red;
        display: flex!important;
    }
    .featured-image-container {
        .text-button {
            font-weight: 400;
            display: none!important;
        }            
    }
    // En la pantalla de detalle lo mostramos siempre!
    .detail-box {
        // border: 2px solid red!important;
        .featured-image-container {
            .text-button {
                font-weight: 400;
                display: block!important;
            }            
        }
    }
}
/* Media queries for desktop */
@media screen and (min-width: 1024px) {
    .search-detail-container {
        border: 0px solid red;
        
        // En el listado de detalles, ponemos sólo uno por línea        
        nav .level-item {
            // width: 50%!important;
        }
    }
    .skeleton-results-img {
        border: 0px solid red;
    }
    .hero-foot {
        .material-icons {
            display: none;
        }
    }

    // GENERAL: PARA TODAS LAS CAJAS DE SUMARIO   
    .box.summary {
        nav {
     
            .level-right {
                margin-top: 0px;

                .subtitle {
                    margin: 0!important;
                }
            }
        }
    }
    // Fixes to the summary
    .summary-level {
        // border: 2px solid red;
        display: flex;
        justify-content: space-between!important;

    }


    .clean-filters-link-tile {
        .clean-filters-link {
            // border: 2px solid red;
            margin: 0!important;
            button {
                position: relative;
                top: -10px;
            }
        }
    }

}
/* Media queries for widescreen */
@media screen and (min-width: 1216px) {

    .search-detail-container {
        border: $weightBorderResponsiveness solid blue;
        nav.result-info {
            flex-direction: row!important;
            .level-item {
                width: 50%;                
            }
        }

        .detail-buttons {
            flex-direction: row!important;
        }

    }
    .skeleton-results-img {
        border: 0px solid blue;
        // height: 100px!important;
    }

    .features-list-container {
        nav {
            flex-flow: nowrap;
            .level-item {
                width: auto!important;
            }
        }
    }

}
/* Media queries for fullhd */
@media screen and (min-width: 1408px) {

    .search-box-container {

        // TOP: Header del cuadro de búsqueda
        .header-search-box-section {
            font-size: 45px;// 2.7rem;
            // border: 2px solid red;
        }
    }
    .search-detail-container {
        border: $weightBorderResponsiveness solid gold;
    }
    .skeleton-results-img {
        border: 0px solid gold;
    }

}
