// COLORS
$red: #f05050;
$blue: #1797be;
$green: #37bc9b;
$orange: #FF902B;
$yellow: #f0ad4e;
$body_bg_color: #525659; // Gris Chrome,
$font_color: #152251;

$linkColor: #0275d8;


// Folios
$A4_Width: 1210px;
$A4_Height: 1712px;

// RESPONSIVENESS
$weightBorderResponsiveness: 0px;
$mqDesktopLg: 1200px; // Usually $screen-lg-desktop;
$mqDesktop: 992px;   // Usually $screen-desktop;
$mqTablet: 768px;   // Usually $screen-sm-min;
$mqMobile: 480px;  // Usually $screen-phone;

$font_family_title: 'Bebas Neue',display;
$font_family_text: 'Lato', system-ui;


// 1. Example using custom border mixin
@mixin border($width, $style, $color) {
  border: $width $style $color;
}


.is-bordered-s1 {
  @include border(1px, solid, #ccc);
}
.is-transparent {
    background: transparent!important;
    border: none!important;
}
.is-shady {
    background: #ffffffaa!important;
    margin-bottom: 20px;
    border-radius: 10px!important;
}
.is-shadiest {
    background: #ffffff22!important;
    margin-bottom: 20px;
    border-radius: 10px!important;
    transition: all .2s ease-in-out;

    &:hover {
        background: #ffffff55!important;
    }
}
.is-fullheight {
    height: 100%;
}
.is-fullwidth {
    width: 100%;
}
.is-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-33 {
    width: 33%;
}
.no-padding {
    padding: 0px!important;
}

.has-color-link {
    color: $linkColor!important;
}