.is-theme-dark {
    
    // Navbar
    .navbar {
        border-bottom: none;
        background-color: rgb(18, 29, 39)!important;
        * {
            color: rgb(132, 220, 0)!important;
            color: rgb(255, 255, 255)!important;
        }
    
    }

    // General for dark theme
    .is-dark {
        .box {
            background-color: rgb(18, 29, 39)!important;
        }
        background-color: rgb(0, 0, 0)!important;
        background-image: none!important;
    }

    // Hero Is Dark
    .hero.is-dark {

        p.title {
            color: whitesmoke!important;
        }

        // Custom elements inside the tree
        div.default-header, span.tree-icon, span.main-node {
            color: whitesmoke!important;
        }

        hr {
            background-color: rgb(55, 55, 55)!important;
        }
    }
}        
