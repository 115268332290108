@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

@import url('./layout.scss');


@import url('./cazatucasa.scss');
@import url('./search.scss');
@import url('./media-queries.scss');
@import url('./carousel.scss');
@import '~leaflet/dist/leaflet.css';

// Icons!!!
// https://material.io/resources/icons/?style=baseline

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$bulma-grey: #dfd8d9;

$gigya-1: #4287c4;
$gigya-2: #1e5ab8;
$gigya-3: #01a3fc;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $gigya-1;
$link: $gigya-3;
$widescreen-enabled: true;
$fullhd: 1242px;
$fullhd-enabled: true;

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter
// $body-background-color: $bulma-grey
$body-background-color: #eee;
$control-border-width: 1px;
$input-border-color: #dbdbdb;
$input-shadow: 1px;

// Import only what you need from Bulma
@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/base/_all.sass';
@import 'bulma/sass/components/_all.sass';
@import 'bulma/sass/elements/_all.sass';
@import 'bulma/sass/form/_all.sass';
@import 'bulma/sass/grid/_all.sass';
@import 'bulma/sass/layout/_all.sass';
// @import 'bulma/sass/elements/button.sass'
// @import 'bulma/sass/elements/container.sass'
// @import 'bulma/sass/elements/title.sass'
// @import 'bulma/sass/form/_all.sass'
// @import 'bulma/sass/components/navbar.sass'
// @import 'bulma/sass/layout/hero.sass'
// @import 'bulma/sass/layout/section.sass'
// @import 'bulma/sass/form/_all.sass'
