@import "./constants";

.search-box-container {

    padding: 12px!important;

    // TOP: Header del cuadro de búsqueda
    .header-search-box-section {
        text-align: center;
        width: 100%;
        font-size: 1.3rem;
        font-size: 2.3vw; // wow!
        text-transform: uppercase;
        font-family: $font_family_title;
        font-weight: 200;
        color: white!important;

        b {
            color: #363636!important;
            font-weight: 500;
        }
        
        .MuiSvgIcon-root
        {
            // border: 2px solid red;
            margin-right: 10px;
            font-size: 2rem;
            top: .40rem !important;
            position: relative;

        }
    }

    // CENTER: Filtros y Cuadro de búsqueda
    .filter-tiles {

        padding: 0 1rem;

        // border: 2px solid red;
        .tile:not(.is-child) {
            display: flex;
        }

        & > .is-vertical > .tile {
            // border: 2px solid red!important;
            flex-wrap: wrap; /* Para que los filtros bajen al comprimirse la ventana */
        }
        .MuiSelect-select {
            background: #2B3A4C;
            background-image: -o-linear-gradient(top, #2B3A4C, #282728);
            background-image: -webkit-gradient(to bottom, #2B3A4C, #282728);
            border-radius: 8px;
            color: whitesmoke!important;
            font-family: $font_family_text;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
            box-shadow: 1px 1px 3px 0 #000000;
            color: whitesmoke;
            text-indent: 5px;
            outline: none!important;
            text-align: left;
        }
        .MuiSelect-icon {
            color: transparent;
            /* Some browsers will not display the caret when using calc, so we put the fallback first */ 
            background: url("../../public/img/down_arrow_icon.png") #2B3A4C no-repeat 98.5% !important; /* !important used for overriding all other customisations */
            background: url("../../public/img/down_arrow_icon.png") #2B3A4C no-repeat calc(100% - 7px) !important; /* Better placement regardless of input width */
            background-position: -1px 5px!important;
            width: 18px;
            // border: 2px solid red;
            // z-index: 100001;
        }
        fieldset {
            border: none!important;
        } 
    }    

    .filter-tiles {
    }
    
    .title-and-filters-section {
        // border: 2px solid red!important;
        .box {
            padding: 0px!important;
        }

        .slider-container {
            // border: 2px solid red;
            width: 100%;
            font-family: $font_family_text;
            font-size: 9px;
            text-transform: uppercase;
            .slider-label {
                font-weight: bold;
                .slider-label-value {
                    font-size: .8rem;
                    font-weight: 400;
                    position: relative;
                    top: -6px;
                }
            }
        }
    }
    
    // BOTTOM: Botones de búsqueda
    .search-box-section {
        // border: 2px solid red!important;
        margin-top: 1rem!important;
        .box {
            padding: 12px!important;
        }
        .search-box {
            // border: 2px dashed red;
            width: 100%;
            padding: 0 0px;
            
            input {
                width: 100%;
                padding: 13px 48px 13px 24px;
                font-size: 1.0rem;
                border: none;
                border-bottom: 2px solid #ccc;
                text-overflow: ellipsis;
    
                outline: none;
                transition: all .4s ease-in-out;
                font-family: $font_family_text;
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #ccc;
                    opacity: 1; /* Firefox */
                }
                &:focus {
                    border-bottom: 2px solid #2B3A4Cdd;
                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #fff;
                        opacity: 1; /* Firefox */
                    }
                }
            }
            button {
                position: absolute;
                margin-right: 10px;
                margin-top: 8px;
                right: 0;
                background: white!important;
                &:hover {
                    svg {
                        color: $linkColor!important;
                    }
                }
    
                border: none;
                background: transparent;
                color: #ccc;
                cursor: pointer;
                transition: all .4s ease-in-out;
                svg {
                    font-size: 1.4rem!important;
                }
            }
        }
    }


    // BOTTOM: Botones de búsqueda
    .control-buttons-tile {
        flex-direction: row-reverse;
        padding: 1.1rem 0px !important;
        button {
            margin-left: 0px;
            border-radius: 15px;
            padding: 22px 30px;
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight: bold;
            font-family: $font_family_text;
            opacity: 0.9;

            // Button disabled
            &[disabled] {
                cursor: not-allowed;
                color: #ccc!important;
                &:hover {
                    color: #ccc!important;
                }
            }
            
        }    
    }


    // 1. MUI Elements
    // Tabs
    .MuiTabs-root {
        .MuiButtonBase-root {
            color: whitesmoke!important;
        }
        .Mui-selected {
            color: $linkColor!important;
        }
    }
    .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 1;
    }
    .Mui-selected {
        .MuiSelect-outlined {
            // border: 2px solid green!important;
            color:green!important;
        }
    }
    .MuiTabPanel-root {
        padding: 0!important;
    }
    // Datagrid fixes
    .MuiDataGrid-cell:focus {
        outline: none!important;
    }
    // Tooltips
    .MuiTooltip-tooltip {
        font-size: 30px!important;
        padding: 10px!important;
        color: $linkColor!important;
        font-family: monospace!important;
        // z-index: 1;
        background-color: #fff!important;
        border-radius: 5px;
        // padding: 10px!important;
        font-size: 22px!important;
        font-weight: bold!important;
        color: $linkColor!important;
        box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)!important;
        // border: 2px solid ;
    }
    .MuiDataGrid-root {
        border: none!important;
    }
    // Slider
    .MuiSlider-colorPrimary {
        color: #0a234f!important;
    }
    // El submenú de los filtros
    .MuiMenu-paper {
        // border: 2px solid red!important;
        border-radius: 12px!important;
        // -webkit-box-shadow: inset 3px 2px 30px 0px rgba(148,148,148,0.48)!important;
        // -moz-box-shadow: inset 3px 2px 30px 0px rgba(148,148,148,0.48)!important;
        // box-shadow: inset 3px 2px 30px 0px rgba(148,148,148,0.48)!important;
        
        box-shadow: 0px 1px 30px 0px rgba(148,148,148,0.48)!important;
        margin-top: 4px;
        .MuiList-root {
            position: relative;
            display: block;
        
            li {
                padding: 0px;

                .MuiListItemText-root {


                    .MuiTypography-root {
                        font-size: 0.7rem;
                        font-family: $font_family_text;
                        font-weight: 600;
                        color: #2B3A4C;
                        text-transform: uppercase;
                    }
                }
        
                &:hover {
                    background:  #6a6a6ad9; // !important;
                    .MuiTypography-root, .MuiButtonBase-root {
                        font-weight: bold!important;
                        color: whitesmoke!important;
                    }
                }

            }

            .Mui-selected {
            
                &:hover {
                    background-color: #6a6a6ad9;// !important;
                    .MuiTypography-root, .MuiButtonBase-root {
                        font-weight: bold!important;
                        color: whitesmoke!important;
                    }
                }
            }
        
        }
    }
    .clean-filters-link-tile {
        padding: 0px!important;
        text-align: right;
        font-size: 0.7rem;
        font-family: $font_family_text;
        transition: all .2s ease-in-out;
        .clean-filters-link {
            width: 100%;
            margin: 1rem 0;
            position: relative;
            cursor: pointer;
            opacity: .8;
            // border: 2px solid red;
            
            svg {
                font-size: 1.2rem!important;
                position: relative;
                top: 0.25rem;
            }

            &:hover {
                opacity: 1;
                color: $linkColor;
            }

        }
    }
    // Para los los listados de debajo de los filtros
    .tile-vertical-aligned-top {
        align-self: flex-start;
    }
    // Para los filtros de tipo asString
    .filter-results {
        // border: 2px solid red;
        font-size: 12px;
        margin-left: 7px!important;
        .as-commas-item {
            list-style: none;
            // border: 2px solid red;
        }
    }
    .filters-summary-box {
        // border: 2px solid red;
        padding: 6px 15px!important;
        text-align: left;
        .filters-summary-tile {
            padding: 0px!important;
        
        

            .one-filter {
                // border: 2px solid red;
                display: inline-block!important;
                font-size: 0.7rem;
                font-family: $font_family_text;
                font-weight: 600;
                color: #2B3A4C;
                text-transform: uppercase;
                padding: 0px 0px;
                margin: 5px 8px 5px 0px;
                
                // background: #6a6a6ad9;
        
                b, ul {
                    display: inline-block!important;
                }
                b {
                    margin-right: 4px;
                }
                .tag {
                    // border: 2px solid red;
                    ul {
                        margin: 0!important;
                        padding: 0!important;
                        // padding-right: 10px!important;
                        li {
                            display: inline-block;
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            
                        }
                    }  
                }
 

                &.to-right {
                    float: right;
                    margin-left: 10px;
                    cursor: pointer;
                    transition: all .2s ease-in-out;
                    &:hover {
                        color: $linkColor;
                    }
                }
            
            }
            .is-info {
                background: #2B3A4Cdd!important;
            }
            .tag-icon {
                font-size: 1.1rem!important;
                margin: 0px 0;
            }
        }

    }



}