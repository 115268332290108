///////// Carousel
/// .
@import "./constants";

#cazatucasa_carousel_v1 {

    display: inline-flex;
    // border: 2px solid red;
    flex-direction: row;
    justify-content: center;
    // align-items: center;
    // padding: 0px;
    margin: 0 !important;
    // height: 300px;
    padding: 0;


    .carousel-list {

        // border: 2px solid red;
        display: flex;
        min-height: 555px;
        width: 100%;
        overflow-x: auto;
        ul {
            display: flex;
            position: absolute;
        }
        li {
            // position: absolute;
        }
        .carousel-item {
            width: 500px !important;
            min-height: 550px!important;
            margin: 0 40px 0 0!important;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 2;
            flex: none;
            
            .MuiCardHeader-title {
                cursor: pointer;
                font-weight: 600;
                // border: 2px solid red;
                display: flex!important;
                align-items: center;
                justify-content: center;
                font-family: $font_family_text;
                color: #7a2e59;
                font-size: 1.4rem;

                // Max height of the title and trim with ellipsis the rest
                height: 3.5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                

            }
            .MuiCardMedia-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                min-height: 270px!important;
                max-height: 270px!important;
                cursor: pointer;

            }
            .MuiCardHeader-action .MuiIconButton-root {
                color: transparent;
            }
            .MuiAvatar-root, .MuiIconButton-root {
                cursor: default;
                // display: none;
            }
            .MuiCardContent-root {
                cursor: pointer;
                font-family: $font_family_text!important;
                text-align: justify;

                color: #63687dcc;

                &.excerpt {
                    
                    text-align: center;
                    // border: 2px solid red;
                    max-height: 120px;
                    min-height: 120px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    /* -webkit-line-clamp: 2; */
                    /* -webkit-box-orient: vertical; */
                    overflow: hidden;
                    display: flex;
                    place-items: center;   
                    padding-top: 40px;      
                    padding-bottom: 20px;;
                    font-size: 1.0rem;
                    color: #63687d;
                       }
               
            }


            .card-media-container {
                .badge-operation {
                    // border: 2px solid red;
                    position: absolute;
                    /* right: 20px; */
                    // background: url(../../public/img/badge-3.png) no-repeat;
                    // background-size: contain;
                    width: 117px;
                    height: 73PX;
                    padding: 13px 0 0 0px;
                    color: white;
                    font-size: 1.1rem;
                    font-family: $font_family_text;
                    font-weight: 600;       
                    margin-top: 27px;  
                    left: -2px;      
                    cursor: pointer; 
                }
            }

            &.alquiler-class {
                .badge-operation {
                    // border: 2px solid blue;
                    background: url(../../public/img/badge-3-blue.png) no-repeat!important;
                    background-size: contain!important;
                }
            }
            &.venta-class {
                .badge-operation {
                    // border: 2px solid red;
                    background: url(../../public/img/badge-3-red.png) no-repeat!important;
                    background-size: contain!important;
                }
            }
            &.traspaso-class {
                .badge-operation {
                    // border: 2px solid orange;
                    background: url(../../public/img/badge-3-orange.png) no-repeat!important;
                    background-size: contain!important;
                }
            }
            &.grey-class {
                .badge-operation {
                    // border: 2px solid grey;
                    background: url(../../public/img/badge-3-grey.png) no-repeat!important;
                    background-size: contain!important;
                }
            }
            &.alquiler-class, &.venta-class, &.traspaso-class, &.grey-class {
                .MuiCardActions-root {
                    //align-items: center;

                    &>.features-list-container {
                        width: 100%;
                        // position: absolute;
                        nav {
                            // border: 2px solid red;
                            width: inherit!important;
                        }
                    }
                }
            }

            &.with-text-small {
                
                .MuiCardHeader-title {
                    // border: 2px solid red!important;    
                    font-size: 1.8rem!important;
                }
            }

            &.with-text-medium {
                
                .MuiCardHeader-title {
                    // border: 2px solid green!important;    
                    font-size: 1.5rem!important;
                }
            }
            &.with-text-large {
                
                .MuiCardHeader-title {
                    // border: 2px solid blue!important;    
                    font-size: 1.0rem!important;
                    line-height: 1.6rem;
                }
            }
        }
    }
}
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  :root {
      --swiper-navigation-size: 44px
  }

  .swiper-button-next,
  .swiper-button-prev {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 50%);
      width: calc(var(--swiper-navigation-size)/ 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color))
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
      opacity: .35;
      cursor: auto;
      pointer-events: none
  }

  .swiper-button-next.swiper-button-hidden,
  .swiper-button-prev.swiper-button-hidden {
      opacity: 0;
      cursor: auto;
      pointer-events: none
  }

  .swiper-navigation-disabled .swiper-button-next,
  .swiper-navigation-disabled .swiper-button-prev {
      display: none !important
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
      font-family: swiper-icons;
      font-size: var(--swiper-navigation-size);
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 10px);
      right: auto
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
      content: 'prev'
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
      right: var(--swiper-navigation-sides-offset, 10px);
      left: auto
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
      content: 'next'
  }

  .swiper-button-lock {
      display: none
  }